import axios, { AxiosResponse } from 'axios';
import * as https from 'https';
import _ from 'lodash/fp';
import { CustomersSettingsFetchByMode } from '../blocs/CustomersBloc/CustomersState';
import { getDefaultAxiosConfig } from '../utils/axiosConfig';

class WooCommerceService {

    static BASE_URL: string = process.env.REACT_APP_WP_API_BASE_URL ?? 'https://local.corpotex.de/wp-json/cptx/v1/';
    //static BASE_URL: string = 'https://corpotex.de/wp-json/cptx/v1/';

    private static isAuthenticated: boolean = false;

    private static getDefaultHeaders() {
        return {
            Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`
        };
    }

    static async checkInitialAuth(): Promise<{ isAuthenticated: boolean; error?: string }> {
        try {
            const result = await axios.get(
                WooCommerceService.getCptxEndpointUrl('auth/status'),
                {
                    ...getDefaultAxiosConfig(),
                    withCredentials: true,
                }
            );
            
            this.isAuthenticated = result.data?.isAuthenticated === true;
            
            if (!this.isAuthenticated) {
                return {
                    isAuthenticated: false,
                    error: 'Nicht authentifiziert. Bitte melde dich im Backend an & versuche es erneut.'
                };
            }

            return { isAuthenticated: true };
        } catch (error: any) {
            console.error('Initial auth check failed:', error);
            
            // Handle rate limiting
            if (error.response?.status === 429) {
                const retryAfter = error.response.headers['retry-after'] || 300;
                return {
                    isAuthenticated: false,
                    error: `Zu viele Anfragen.`
                };
            }
            
            // Handle network errors
            if (error.code === 'ERR_NETWORK') {
                return {
                    isAuthenticated: false,
                    error: 'Verbindung zum Server fehlgeschlagen. Bitte überprüfen Sie Ihre Internetverbindung.'
                };
            }
            
            // Handle other errors
            return {
                isAuthenticated: false,
                error: 'Authentifizierung fehlgeschlagen.'
            };
        }
    }

    /**
     * Load article data from WeClapp.
     *
     * @param articleNumber
     * @return API-Response
     */
    loadArticle = async ( articleNumber: string ) => {
        let params = `sku=${articleNumber}`;

        let result: AxiosResponse = await axios.get(
            WooCommerceService.getProxyEndpointUrl( `products?${params}` ),
            {
                headers: WooCommerceService.getDefaultHeaders(),
                httpsAgent: new https.Agent( {
                    rejectUnauthorized: false,
                } ),
            } );
        return result?.data;
    };

    postProduct = async ( wooCommerceData: any ) => {
        let result: AxiosResponse = await axios.post(
            WooCommerceService.getProxyEndpointUrl( `products` ),
            wooCommerceData,
            {
                headers: WooCommerceService.getDefaultHeaders(),
                httpsAgent: new https.Agent( {
                    rejectUnauthorized: false,
                } ),
            } );

        return result?.data;
    };

    updateProduct = async ( id: string, wooCommerceData: any ) => {
        let result: AxiosResponse = await axios.post(
            WooCommerceService.getProxyEndpointUrl( `products/${id}` ),
            wooCommerceData,
            {
                headers: WooCommerceService.getDefaultHeaders(),
                httpsAgent: new https.Agent( {
                    rejectUnauthorized: false,
                } ),
            } );

        return result?.data;
    };

    deleteProduct = async(id: string) => {
        let result: AxiosResponse = await axios.delete(
            WooCommerceService.getProxyEndpointUrl( `products/${id}` ),
            {
                headers: WooCommerceService.getDefaultHeaders(),
                httpsAgent: new https.Agent( {
                    rejectUnauthorized: false,
                } ),
            } );

        return result?.data;
    }

    postVariantArticle = async ( variantArticleId: string ) => {
        let params = `id=${variantArticleId}&full=1`;

        let result: AxiosResponse = await axios.get(
            WooCommerceService.getCptxEndpointUrl( `utils/importVariantArticle?${params}` ),
            {
                headers: WooCommerceService.getDefaultHeaders(),
                httpsAgent: new https.Agent( {
                    rejectUnauthorized: false,
                } ),
            } );

        return result?.data;
    };

    importImagesForArticle = async ( articleNumber: string ) => {
        let params                = `sku=${articleNumber}`;
        let result: AxiosResponse = await axios.get(
            WooCommerceService.getCptxEndpointUrl( `images/importImageFromWeClapp?${params}` ),
            {
                headers: WooCommerceService.getDefaultHeaders(),
                httpsAgent: new https.Agent( {
                    rejectUnauthorized: false,
                } ),
            },
        );

        return result?.data;
    };

    importImagesForBrandArticle = async ( productId: string, brand: string, update_existing: boolean = false ) => {
        let params                = `productId=${productId}&brand=${brand}&update=${update_existing}`;
        let result: AxiosResponse = await axios.get(
            WooCommerceService.getCptxEndpointUrl( `images/importBrandProductImages?${params}` ),
            {
                headers: WooCommerceService.getDefaultHeaders(),
                httpsAgent: new https.Agent( {
                    rejectUnauthorized: false,
                } ),
            },
        );

        return result?.data;
    };

    updateGenderAttributes = async () => {
        let result: AxiosResponse = await axios.get(
            WooCommerceService.getCptxEndpointUrl( `utils/importGenderTerms` ),
            {
                headers: WooCommerceService.getDefaultHeaders(),
                httpsAgent: new https.Agent( {
                    rejectUnauthorized: false,
                } ),
            },
        );

        return result?.data;
    };

    updateVariantAttributeOptions = async () => {
        let result: AxiosResponse = await axios.get(
            WooCommerceService.getCptxEndpointUrl( `utils/loadVariantAttributeOptions` ),
            {
                headers: WooCommerceService.getDefaultHeaders(),
                httpsAgent: new https.Agent( {
                    rejectUnauthorized: false,
                } ),
            },
        );

        return result?.data;
    };

    updateBrandAttributes = async () => {
        let result: AxiosResponse = await axios.get(
            WooCommerceService.getCptxEndpointUrl( `utils/importBrandCategories` ),
            {
                headers: WooCommerceService.getDefaultHeaders(),
                httpsAgent: new https.Agent( {
                    rejectUnauthorized: false,
                } ),
            },
        );

        return result?.data?.terms;
    };

    loadCustomer = async ( customerNumber: string, fetchBy: CustomersSettingsFetchByMode ) => {
        let param = `customerNumber=${customerNumber}`;

        if ( fetchBy == "id" ) {
            param = `id=${customerNumber}`;
        }

        let result: AxiosResponse = await axios.get(
            WooCommerceService.getCptxEndpointUrl( `webshop/customer?${param}` ),
            {
                headers: WooCommerceService.getDefaultHeaders(),
                httpsAgent: new https.Agent( {
                    rejectUnauthorized: false,
                } ),
            },
        );

        return result?.data;
    };

    importCustomer = async ( customerNumber: string, fetchBy: CustomersSettingsFetchByMode ) => {
        let param = `customerNumber=${customerNumber}`;

        if ( fetchBy == "id" ) {
            param = `id=${customerNumber}`;
        }

        let result: AxiosResponse = await axios.get(
            WooCommerceService.getCptxEndpointUrl( `utils/importCustomer?${param}` ),
            {
                headers: WooCommerceService.getDefaultHeaders(),
                httpsAgent: new https.Agent( {
                    rejectUnauthorized: false,
                } ),
            },
        );

        return result?.data;
    };

    updateCustomer = async ( customerNumber: string, fetchBy: CustomersSettingsFetchByMode ) => {
        let param = `customerNumber=${customerNumber}`;

        if ( fetchBy == "id" ) {
            param = `id=${customerNumber}`;
        }

        let result: AxiosResponse = await axios.get(
            WooCommerceService.getCptxEndpointUrl( `utils/updateCustomer?${param}` ),
            {
                headers: WooCommerceService.getDefaultHeaders(),
                httpsAgent: new https.Agent( {
                    rejectUnauthorized: false,
                } ),
            },
        );

        return result?.data;
    };

    static transformWeClappToWooCommerceData = ( weClappData: any ) => {
        let wooCommerceData: any;

        /**
         * Auto detect what type of weClappData should be transformed:
         * (based on articleNumber/variantArticleNumber property present on object).
         * Choose method to transform based on data type.
         */

        // Transform single article
        if ( !_.isUndefined( weClappData.articleNumber ) ) {
            wooCommerceData = WooCommerceService.transformSingleArticleToWcProduct( weClappData );
        }

        // Transform variantArticle
        if ( !_.isUndefined( weClappData.variantArticleNumber ) ) {
            wooCommerceData = WooCommerceService.transformVariantArticleToWcProduct( weClappData );
        }

        return wooCommerceData;
    };

    static transformVariantArticleToWcProduct = ( weClappData: any ) => {
        return {
            'status':            'publish',
            'sku':               weClappData?.variantArticleNumber ?? '',
            'name':              'Product',
            'short_description': '',
            'description':       '',
            'dimensions':        {
                "length": "",
                "width":  "",
                "height": "",
            },
            'dimensions.height': null,
            'dimensions.width':  null,
            'dimensions.length': null,
            'categories':        [],
            'type':              'variable', // 'simple' | 'variable'
            'weight':            null,
            // 'attributes':        $product_attribute,
            'variations':      [], // List of variation product ids
            'sync_variations': true,
        };
    };

    static transformSingleArticleToWcProduct = ( weClappData: any ) => {
        return {
            'status':            'publish',
            'sku':               weClappData?.articleNumber ?? '',
            'name':              weClappData?.name ?? 'Product',
            'short_description': '',
            'description':       weClappData?.description ?? '',
            'dimensions':        {
                "length": "",
                "width":  "",
                "height": "",
            },
            'dimensions.height': null,
            'dimensions.width':  null,
            'dimensions.length': null,
            'categories':        [],
            'type':              'simple', // 'simple' | 'variable'
            'weight':            weClappData?.articleNetWeight ?? null,
            // 'attributes':        $product_attribute,
            'variations': null, // List of variation product ids
        };
    };

    static getProxyEndpointUrl = ( endpoint: string ) => {
        return `${WooCommerceService.BASE_URL}woocommerce/proxy/${endpoint}`;
    };

    static getCptxEndpointUrl = ( endpoint: string ) => {
        return WooCommerceService.BASE_URL + endpoint;
    };
}

export default WooCommerceService;
