// @ts-ignore
import { BehaviorSubject } from 'rxjs';
import WeClappService from '../../services/WeClappService';
import WooCommerceService from '../../services/WooCommerceService';
import BrandsState, { ManufacturerData } from './BrandsState';
import axios, { AxiosResponse } from 'axios';
import _ from 'lodash/fp';
import { toast } from 'react-toastify';
import { getDefaultAxiosConfig } from '../../utils/axiosConfig';

export default class BrandsBloc {
    private readonly stateSubject: BehaviorSubject<BrandsState>;
    private weClapp: WeClappService;
    private wooCommerce: WooCommerceService;

    constructor() {
        this.stateSubject = new BehaviorSubject( new BrandsState() );
        this.weClapp      = new WeClappService();
        this.wooCommerce  = new WooCommerceService();

        this.loadManufacturers();
    }

    loadManufacturers = async () => {
        this.setLoadingState( 'loadManufacturers', true );
        let manufacturers = await this.weClapp.loadAllManufacturers();
        manufacturers     = manufacturers.result;

        this.setManufacturers( manufacturers.map( ( entry: any ) => ( { name: entry.name, id: entry.id } ) ) );

        this.setLoadingState( 'loadManufacturers', false );
    };

    loadWeClappManufacturerArticles = async ( manufacturerNames: string[] ) => {
        this.setLoadingState( 'loadManufacturerArticles', true );
        console.log( `Loading ${JSON.stringify( manufacturerNames )} articles.` );
        manufacturerNames = manufacturerNames ?? [];
        let loadActive    = this.currentState.settingsState.fetchActive === 'active';
        let loadProp      = this.currentState.settingsState.loadProp;

        let params = `properties=${loadProp === 'id' ? 'id' : 'articleNumber'}&active-eq=${loadActive ? 'true'
                                                                                                      : 'false'}&manufacturerName-in=${JSON.stringify( manufacturerNames )}`;

        let result: AxiosResponse = await axios.get(
            WeClappService.getEndpointUrl( `article/count?${params}` ),
            getDefaultAxiosConfig()
        );

        let articleCount = result?.data?.result;

        let articleParams                = params + `&pageSize=500`;
        let articleResult: AxiosResponse = await axios.get(
            WeClappService.getEndpointUrl( `article?${articleParams}` ),
            getDefaultAxiosConfig()
        );

        let allArticles: any[] = [];

        let articles = articleResult?.data?.result;
        articles     = articles.map( ( article: any ) => article[loadProp === 'id' ? 'id' : 'articleNumber'] );
        allArticles  = _.concat( allArticles, articles );

        console.log( `Got initial: ${allArticles.length} articles.` );

        if ( allArticles.length < articleCount ) {
            console.log( `Need to fetch more articles (${articleCount})` );

            let i          = 1;
            let maxRepeats = 600;
            while ( allArticles.length < articleCount && i < maxRepeats ) {
                i++;

                let articleResult: AxiosResponse = await axios.get(
                    WeClappService.getEndpointUrl( `article?${articleParams}&page=${i}` ),
                    getDefaultAxiosConfig()
                );

                let articles = articleResult?.data?.result;
                articles     = articles.map( ( article: any ) => article[loadProp === 'id' ? 'id' : 'articleNumber'] );

                if ( articles.length <= 0 ) {
                    i = 999999;
                }

                console.log( `Got page ${i}: ${articles.length} articles.` );

                allArticles = _.concat( allArticles, articles );
            }

            console.log( `Loaded ${allArticles.length}/${articleCount} articles.` );
        }

        this.setArticles( allArticles );

        this.setLoadingState( 'loadManufacturerArticles', false );
    };

    loadWeClappBrandArticles = async ( brandName: string ) => {
        this.setLoadingState( 'loadBrandArticles', true );
        console.log( `Loading ${JSON.stringify( brandName )} articles.` );
        let loadActive    = this.currentState.settingsState.fetchActive === 'active';
        let loadProp      = this.currentState.settingsState.loadProp;

        let params = `properties=${loadProp === 'id' ? 'id' : 'articleNumber'}&active-eq=${loadActive ? 'true'
                                                                                                      : 'false'}&customAttribute8193537-eq=${brandName}`;

        let result: AxiosResponse = await axios.get(
            WeClappService.getEndpointUrl( `article/count?${params}` ),
            getDefaultAxiosConfig()
        );

        let articleCount = result?.data?.result;

        let articleParams                = params + `&pageSize=500`;
        let articleResult: AxiosResponse = await axios.get(
            WeClappService.getEndpointUrl( `article?${articleParams}` ),
            getDefaultAxiosConfig()
        );

        let allArticles: any[] = [];

        let articles = articleResult?.data?.result;
        articles     = articles.map( ( article: any ) => article[loadProp === 'id' ? 'id' : 'articleNumber'] );
        allArticles  = _.concat( allArticles, articles );

        console.log( `Got initial: ${allArticles.length} articles.` );

        if ( allArticles.length < articleCount ) {
            console.log( `Need to fetch more articles (${articleCount})` );

            let i          = 1;
            let maxRepeats = 20;
            while ( allArticles.length < articleCount && i < maxRepeats ) {
                i++;

                let articleResult: AxiosResponse = await axios.get(
                    WeClappService.getEndpointUrl( `article?${articleParams}&page=${i}` ),
                    getDefaultAxiosConfig()
                );

                let articles = articleResult?.data?.result;
                articles     = articles.map( ( article: any ) => article[loadProp === 'id' ? 'id' : 'articleNumber'] );

                if ( articles.length <= 0 ) {
                    i = 999999;
                }

                console.log( `Got page ${i}: ${articles.length} articles.` );

                allArticles = _.concat( allArticles, articles );
            }

            console.log( `Loaded ${allArticles.length}/${articleCount} articles.` );
        }

        this.setArticles( allArticles );

        this.setLoadingState( 'loadBrandArticles', false );
    }

    loadWooCommerceBrandArticles = async ( manufacturerName: string ) => {
        this.setLoadingState( 'loadWooCommerceBrandArticles', true );

        let result: AxiosResponse = await axios.get(
            WooCommerceService.getCptxEndpointUrl( `products/getBrandProductIds?brand=${manufacturerName}` ),
            getDefaultAxiosConfig()
        );

        let brandArticles = result?.data;
        console.log( brandArticles );

        this.setArticles( brandArticles );

        this.setLoadingState( 'loadWooCommerceBrandArticles', false );
    };

    filterExistingArticles = async () => {
        if ( this.currentState.settingsState.loadProp === 'id' ) {
            toast.error( `❌ Filtern ist nur bei geladenen SKUs verfügbar.` );
            return;
        }

        this.setLoadingState( 'filterArticles', true );

        let articleNumberChunks = _.chunk( 500, this.currentState.articles );
        let currChunkIndex      = 1;
        let totalChunks         = articleNumberChunks?.length ?? 0;

        let filteredArticleNumbers: any[] = [];

        for ( let chunk of articleNumberChunks ) {
            if ( chunk.length === 0 ) {
                console.log( `❌ Skipping chunk ${currChunkIndex}/${totalChunks} because length = 0.` );
                currChunkIndex++;
                continue;
            }

            let skus = chunk.join( ',' );
            console.log( `⌛️ Filtering chunk ${currChunkIndex}/${totalChunks}.` );

            let result: AxiosResponse = await axios.get(
                WooCommerceService.getCptxEndpointUrl( `utils/filterOutExistingSkus?skus=${skus}` ),
                getDefaultAxiosConfig()
            );
            let newArticleSkus        = result?.data;

            console.log( newArticleSkus );
            if ( typeof newArticleSkus === "undefined" ) {
                toast.error( `❌ Chunk filtering failed for ${currChunkIndex}/${totalChunks}.` );
                console.log( `❌ Chunk filtering failed for ${currChunkIndex}/${totalChunks}.` );
                currChunkIndex++;
                return;
            }

            filteredArticleNumbers = _.concat( filteredArticleNumbers, newArticleSkus );
            toast.success( `🔥 Filtered chunk ${currChunkIndex}/${totalChunks}: ${newArticleSkus.length} new articles remained.` );
            console.info( `🔥Deleted chunk ${currChunkIndex}/${totalChunks}: ${newArticleSkus.length} new articles remained.` );

            currChunkIndex++;
        }

        this.setArticles( filteredArticleNumbers );

        this.setLoadingState( 'filterArticles', false );
    };

    setArticles = ( articles: any[] ) => {
        let currentState: BrandsState = this.currentState;
        currentState.articles         = articles;
        this.pushState( currentState );

        return currentState;
    };

    setManufacturers = ( manufacturers: ManufacturerData[] ) => {
        let currentState: BrandsState = this.currentState;
        currentState.manufacturers    = manufacturers;
        this.pushState( currentState );

        return currentState;
    };

    setSettings = ( key: string, value: any ) => {
        let currentState: BrandsState   = this.currentState;
        currentState.settingsState[key] = value;
        this.pushState( currentState );

        return currentState;
    };

    setLoadingState = ( key: string, loading: boolean = true ) => {
        let currentState: BrandsState   = this.currentState;
        currentState.loadingStates[key] = loading;
        this.pushState( currentState );

        return currentState;
    };

    setOpenState = ( key: string, loading: boolean = true ) => {
        let currentState: BrandsState = this.currentState;
        currentState.openStates[key]  = loading;
        this.pushState( currentState );

        return currentState;
    };

    get currentState() {
        return this.getStateSubject().getValue();
    }

    pushState = ( newState: BrandsState ) => {
        this.getStateSubject().next( newState );
        return newState;
    };

    getStateSubject = () => {
        return this.stateSubject;
    };
}
